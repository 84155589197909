import React from "react";

export default function BackgroundIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4.65H4C3.25442 4.65 2.65 5.25442 2.65 6V14C2.65 14.7456 3.25442 15.35 4 15.35H16C16.7456 15.35 17.35 14.7456 17.35 14V6C17.35 5.25442 16.7456 4.65 16 4.65ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4Z"
        fill="#606B85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.325C9.35111 6.325 8.82505 6.85107 8.82505 7.5C8.82505 8.14894 9.35111 8.675 10 8.675C10.649 8.675 11.175 8.14894 11.175 7.5C11.175 6.85107 10.649 6.325 10 6.325ZM8.17505 7.5C8.17505 6.49208 8.99213 5.675 10 5.675C11.008 5.675 11.825 6.49208 11.825 7.5C11.825 8.50792 11.008 9.325 10 9.325C8.99213 9.325 8.17505 8.50792 8.17505 7.5Z"
        fill="#606B85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.325C8.24654 10.325 6.82505 11.7465 6.82505 13.5C6.82505 13.6795 6.67954 13.825 6.50005 13.825C6.32056 13.825 6.17505 13.6795 6.17505 13.5C6.17505 11.3875 7.88756 9.675 10 9.675C12.1125 9.675 13.825 11.3875 13.825 13.5C13.825 13.6795 13.6795 13.825 13.5 13.825C13.3206 13.825 13.175 13.6795 13.175 13.5C13.175 11.7465 11.7536 10.325 10 10.325Z"
        fill="#606B85"
      />
    </svg>
  );
}
