import React from "react";

export default function FlipCameraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#707578">
          <path
            d="M12 3.5c4.694 0 8.5 3.806 8.5 8.5s-3.806 8.5-8.5 8.5-8.5-3.806-8.5-8.5S7.306 3.5 12 3.5zm0 1c-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5 4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5zm1.367 3.429C14.959 8.492 16.1 10.215 16.1 12c0 1.16-.481 2.294-1.256 3.1h.356c.245 0 .45.177.492.41l.008.09c0 .245-.177.45-.41.492l-.09.008h-2.008l-.048-.003-.034-.005c-.088-.017-.167-.055-.233-.11l-.06-.06c-.037-.044-.067-.096-.087-.151l-.001-.004-.012-.04c-.004-.012-.007-.024-.009-.037v-.005c-.005-.024-.006-.042-.007-.06L12.7 13.6c0-.276.224-.5.5-.5.245 0 .45.177.492.41l.008.09v1.176c.833-.6 1.4-1.676 1.4-2.776 0-1.373-.886-2.71-2.067-3.129-.26-.092-.396-.377-.304-.638.092-.26.378-.396.638-.304zm-2.559-.03c.03.001.058.004.086.01l.062.016.018.006c.04.015.075.034.108.056.086.059.152.143.188.242l.001.004.008.025c.006.017.01.034.013.052l.008.09v2c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41l-.008-.09V9.224c-.833.6-1.4 1.676-1.4 2.776 0 1.373.886 2.71 2.067 3.129.26.092.396.377.304.638-.092.26-.378.396-.638.304C9.041 15.508 7.9 13.785 7.9 12c0-1.16.481-2.294 1.255-3.1H8.8c-.245 0-.45-.177-.492-.41L8.3 8.4c0-.245.177-.45.41-.492L8.8 7.9h2.008z"
            transform="translate(-233 -689) translate(-1 673) translate(37 16) translate(197.298)"
          />
        </g>
      </g>
    </svg>
  );
}
